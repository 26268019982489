import React, { useState } from "react"
import { Accordion } from "react-bootstrap"
import "./accordion.css"
import MinusIcon from "../assets/minus-accordion.svg"
import PlusIcon from "../assets/plus-accordion.svg"

const CustomAccordion = ({ header, body }) => {
  const [activeKey, setActiveKey] = useState("1")

  const handleToggle = key => {
    if (activeKey === key) {
      setActiveKey(null)
    } else {
      setActiveKey(key)
    }
  }

  return (
    <Accordion defaultActiveKey="0" activeKey={activeKey}>
      <Accordion.Toggle
        eventKey="0"
        onClick={() => handleToggle("0")}
        className="accordion-header"
      >
        <div
          className={
            activeKey === "0"
              ? "accordion-header-text active"
              : "accordion-header-text"
          }
        >
          {header}
        </div>
        {activeKey === "0" ? (
          <MinusIcon className="accordion-icon" />
        ) : (
          <PlusIcon className="accordion-icon" />
        )}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="0">
        <div className="accordion-content">{body}</div>
      </Accordion.Collapse>
    </Accordion>
  )
}

export default CustomAccordion
