import React from "react"
import Banner from "./banner"
import "./glossary.css"
import CustomAccordion from "./accordion"

const Glossary = () => {
  const glossaryItems = [
    {
      header: "Basic Home Loan",
      body: (
        <p>
          Basic variable rate home loans are extremely popular due to their low
          fees and interest rates but the trade-off is limited features.
        </p>
      ),
    },
    {
      header: "Break costs",
      body: (
        <p>
          The cost of paying off your fixed-rate loan or switching to a variable
          rate before the end of the fixed rate period.
        </p>
      ),
    },
    {
      header: "Capital growth",
      body: (
        <p>
          We all know fine wine gets better over time, and sometimes your
          property does too. Capital growth is the increase in the value of your
          property over time.
        </p>
      ),
    },
    {
      header: "Cashback",
      body: (
        <p>
          Who doesn’t love a cashback deal? In the home loan world, cashback is
          when a lender offers an incentive for refinancing with them. Make sure
          to compare their advertised interest rates, comparison rates and other
          fees to make sure it’s worth it.
        </p>
      ),
    },
    {
      header: "Comparison rate",
      body: (
        <p>
          A comparison rate makes the world of home loans a little clearer by
          factoring in all costs associated with a loan - think interest rate
          plus fees and charges. By understanding the true cost of a loan,
          you’re better informed to compare loans from different lenders.
        </p>
      ),
    },
    {
      header: "Conveyancing",
      body: (
        <p>
          This is a legal document outlining the terms and conditions for the
          sale of a property, between the parties. Always seek advice from your
          solicitor or conveyancer before signing.
        </p>
      ),
    },
    {
      header: "Contract for sale",
      body: (
        <p>
          This is the legal process of transferring the ownership of real
          estate.
        </p>
      ),
    },
    {
      header: "Disbursements",
      body: (
        <p>
          Disbursements are incidental costs incurred by a solicitor when acting
          for a client, such as searches, certificates, past records, etc.
        </p>
      ),
    },
    {
      header: "Equity",
      body: (
        <p>
          The difference between your home’s market value and what you owe on
          your loan.
        </p>
      ),
    },
    {
      header: "Establishment fee",
      body: (
        <p>
          This is a fee that is charged by banks when applying for a new home
          loan.
        </p>
      ),
    },
    {
      header: "First home owner grant (FHOG)",
      body: (
        <p>
          Buying your first home? The FHOG is a one-off government grant to help
          you in paying for your first home.
        </p>
      ),
    },
    {
      header: "Fixed interest rate",
      body: (
        <p>
          If you value consistency, a fixed interest rate is probably for you.
          Find a rate, lock it in and keep it the same repayment for a set
          period or ‘fixed term’ - often between one and five years.
        </p>
      ),
    },
    {
      header: "Guarantor",
      body: (
        <p>
          This is person who agrees to be responsible for the payment of the
          loan if the borrower defaults or is unable to pay – usually a parent.
        </p>
      ),
    },
    {
      header: "Home Equity Loan",
      body: (
        <p>
          Home equity loans or lines of credit allow borrowers to unlock the
          equity in their property for any purpose such as renovating,
          investing, motor vehicles, education, etc. Home equity loans provide a
          low cost option to other forms of lending with the flexibility of
          interest-only repayments.
        </p>
      ),
    },
    {
      header: "Home loan deposit",
      body: (
        <p>
          A home loan deposit is the initial amount you put down as a
          contribution to the purchase price of a house - typically 20% of the
          property value but it some cases it can be as low as 5%.
        </p>
      ),
    },
    {
      header: "Interest only home loan",
      body: (
        <p>
          For a set period (usually 1 to 5 years) only interest is paid off your
          home loan, while the principal remains the same. At the end of the
          interest only period, the loan repayments will automatically switch to
          principal and interest, meaning your loan repayments will be higher.
        </p>
      ),
    },
    {
      header: "Interest rate",
      body: (
        <p>
          The interest rate is the percentage a lender uses to calculate
          interest amount charged on your home loan.
        </p>
      ),
    },
    {
      header: "Investment Property Loan",
      body: (
        <p>
          If you are planning to buy a residential or commercial property to
          rent out and earn an income from, you might decide to take out an
          investment property loan to fund the purchase.
        </p>
      ),
    },
    {
      header: "Loan term",
      body: (
        <p>
          Simple, the loan term is how long the loan will last. It can be
          subject to change if you pay it off early or choose to refinance.
        </p>
      ),
    },
    {
      header: "Line of credit loans",
      body: (
        <p>
          Line of credit loans are similar to overdraft facilities where income
          and expenses are paid and withdrawn from a single account. The concept
          is to minimise interest by depositing your salary directly into the
          loan account and only withdrawing living expenses as required. The
          downside is that without strict budgeting and controls, the loan could
          remain fully drawn. Instead, if you are looking to minimise interest,
          consider a suitable home loan with a 100% offset facility.
        </p>
      ),
    },
    {
      header: "LMI - Lenders Mortgage Insurance",
      body: (
        <p>
          Most lenders charge LMI if the home loan is greater than 80% of the
          property’s value. Although LMI only protects the lender if the
          borrower can’t repay the loan, the borrower needs to pay the insurance
          premium.
        </p>
      ),
    },
    {
      header: "Low-doc home loans",
      body: (
        <p>
          Low-doc home loans suit self-employed borrowers who can afford to
          repay a loan but are unable to provide full financial statements or
          where they have an irregular or lumpy income stream.
        </p>
      ),
    },
    {
      header: "LVR - Loan to value ratio",
      body: (
        <p>
          This is the amount of your loan compared to the value of your
          property, expressed as a percentage. Lenders place a limit on the LVR
          depending on things such as the type of loan, property, location and
          financial position of the borrower.
        </p>
      ),
    },
    {
      header: "Mortgage",
      body: (
        <p>
          A mortgage is your lender's parachute. This legal document gives the
          lender conditional ownership of the property as security for the
          repayment of the loan.
        </p>
      ),
    },
    {
      header: "Mortgagee",
      body: <p>This is the entity who lends the money to the borrower.</p>,
    },
    {
      header: "Mortgagor",
      body: <p>This is the entity who borrows money from the lender.</p>,
    },
    {
      header: "Offset account",
      body: (
        <p>
          Want to save more time and money? Set up an offset account. This is an
          everyday bank account linked to your home loan. When money comes in,
          the balance is offset against the amount owing on your home loan,
          helping to reduce interest paid and the overall loan term.
        </p>
      ),
    },
    {
      header: "Pre-approval",
      body: (
        <p>
          A pre-approval is a provisional figure of what a lender might be able
          to lend you, giving you confidence to search for a new home within
          your budget.
        </p>
      ),
    },
    {
      header: "Principal",
      body: (
        <p>
          This is the amount of the loan owed to the lender and which the
          interest is calculated against.
        </p>
      ),
    },
    {
      header: "Refinancing",
      body: (
        <p>
          Simply put, refinancing means moving your home loan to a different
          lender. When life changes, so do your home loan goals and refinancing
          allows you to find a new loan that best suits you.
        </p>
      ),
    },
    {
      header: "Redraw facility",
      body: (
        <p>
          Redraw is feature that provides borrowers access to any surplus funds
          they have over-paid into their home loan account. So if you make extra
          repayments on your home loan, redraw allows you to access these funds
          at any time.
        </p>
      ),
    },
    {
      header: "Refix",
      body: (
        <p>
          Fixed rate term coming to an end? Don’t stress - you can lock in a new
          fixed rate with your lender leading up to the expiration date. Not
          currently on a fixed rate? You can fix your home loan interest rate at
          any time.
        </p>
      ),
    },
    {
      header: "Revert Rate",
      body: (
        <p>
          Revert rate is the interest rate a fixed rate home loan switches to at
          the end of the term. Typically, it’s a higher rate than what you were
          paying so make sure you look at refinancing as an alternate option.
        </p>
      ),
    },
    {
      header: "Security",
      body: (
        <p>
          Security refers to the property being used as collateral in exchange
          for the home loan.
        </p>
      ),
    },
    {
      header: "Serviceability",
      body: (
        <p>
          This refers to a borrower’s capacity and meet the loan repayments,
          based on income, expenses and all other debts.
        </p>
      ),
    },
    {
      header: "Settlement or drawdown",
      body: (
        <p>
          Settlement is the legalities of transferring property ownership from
          seller to buyer.
        </p>
      ),
    },
    {
      header: "SMSF – Self Managed Super Fund",
      body: (
        <p>
          A SMSF is a way of saving for your retirement. The difference between
          a SMSF and an Industry Super Fund is that the members of a SMSF are
          usually the trustees and make all the investment decisions. Using a
          SMSF to purchase investment properties has become very popular in
          recent times.
        </p>
      ),
    },
    {
      header: "Specialist home loans",
      body: (
        <p>
          Specialist or non-conforming home loans are designed to benefit
          borrowers who pass loan servicing benchmarks but don’t meet mainstream
          credit rules, due to a one-off situation that impaired their credit
          rating.
        </p>
      ),
    },
    {
      header: "Split Home Loans",
      body: (
        <p>
          Split or blended home loans allow borrowers to divide their home loans
          into partly fixed and variable portions. This provides borrowers with
          the flexibility of a variable rate and the added certainty of a fixed
          rate loan.
        </p>
      ),
    },
    {
      header: "Standard Variable Rate Home Loan",
      body: (
        <p>
          Standard variable rate loans are known for their flexibility and
          features such as partly fixing, loan splits, offset, additional
          repayments and redraw.
        </p>
      ),
    },
    {
      header: "Title",
      body: <p>This is a registered document stating who owns the property.</p>,
    },
    {
      header: "Variable interest rate",
      body: (
        <p>
          A variable interest rate is subject to change over time. Variable
          rates are like a roller coaster - they go up, they go down and they
          favour the risk-takers.
        </p>
      ),
    },
  ]
  return (
    <div className="glossary">
      <Banner />
      <div className="glossary-body">
        <div className="glossary-body-header">
          <h1>
            Home loans <strong>glossary</strong>
          </h1>
          <p>
            We’ve put together a helpful list of need-to-know home loan language
            to make Compare n Save even more simple.
          </p>
        </div>
        <div className="glossary-body-accordion">
          {glossaryItems.map((item, index) => (
            <CustomAccordion
              key={index}
              header={item.header}
              body={item.body}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Glossary
