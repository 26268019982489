import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Glossary from "../components/glossary"

const GlossaryPage = () => (
  <Layout>
    <SEO title="Glossary" />
    <Glossary />
  </Layout>
)

export default GlossaryPage
